(function ($) {
    $(function () {

        $('#advise_type').on('change', function (){
            $(this).parents('form').submit();
        });


        $('#supplied_personal_documents').on('change', function () {
            var $this = $(this);
            $.post('/mijn-dossier/bewijsstukken/persoonlijke-documenten', {
                supplied_personal_documents: $this.val()
            }, function () {
                $this.attr('disabled', 'disabled');
            });
        });

        $('.table-upload tr').RequiredDocument();
    });

}(jQuery));