(function($, w) {
    var $w = $(w);

    function Tooltip($el, options) {
        this.$el = $el;
        var tip = this.$tip = $el.find(options.selector).hide();
        this._options = options;

        if (options.click === false) {
            tip.on('mouseenter', function () {
               return false;
            });

            this.$el
                .on('mouseenter', $.proxy(this.show, this))
                .on('mouseleave', $.proxy(this.hide, this))
                .on('click', function () { return false; });
        } else {
            this.$el
                .on('click', $.proxy(this.toggle, this));
        }

        tip.css({
            position:'absolute',
            zIndex: 100
        });
    }

    Tooltip.prototype = {
        _handlers : {
            resize: function () {

            }
        },
        show: function (e) {
            e.preventDefault();
            this.reposition();
            $w.one('click', $.proxy(this.hide, this));

            this.$tip.fadeIn(this._options.speed);
        },
        hide: function (e) {
            e.preventDefault();
            this.$tip.fadeOut(this._options.speed);
        },
        toggle: function (e) {
            return this.$tip.is(':visible') ? this.hide(e) : this.show(e);
        },
        reposition: function () {
            var windowScroll  = $w.scrollTop(),
                windowVCenter = windowScroll + ($w.innerHeight() / 2),
                windowHCenter = ($w.innerWidth() / 2),

                elPosition  = this.$el.offset(),
                elHeight    = this.$el.outerHeight(),
                elWidth     = this.$el.outerWidth(),
                elVCenter   = elPosition.top - (elHeight/2),
                elHCenter   = elPosition.left - (elWidth/2);

            this.$tip.show();

            var tipWidth = this.$tip.outerWidth(),
                tipHeight = this.$tip.outerHeight();

            this.$tip.hide();

            var pos = {};
            if (elVCenter < windowVCenter) {
                pos.top = elHeight;
            } else {
                pos.top = -tipHeight;
            }

            if (elHCenter < windowHCenter) {
                pos.left = elWidth;
            } else {
                pos.left = -tipWidth;
            }

            this.$tip.css(pos);
        }
    }

    var defaults = {
        selector:       '.tip',
        click:          true,
        speed:          150,
        smartPosition:  true
    };

    $.fn.Tooltip = function (options) {
        options = options || {};
        options = $.extend(defaults, options);

        return $(this).each(function () {
            var $el = $(this),
                tooltip = $el.data('tooltip');

            if (!tooltip) { $el.data('tooltip', new Tooltip($el, options)) };
        });
    };

}(jQuery, window));