(function ($, plupload) {
    var defaults = {
        flash_swf_url : '/plupload/Moxie.swf',
        silverlight_xap_url : '/plupload/Moxie.xap',
    };

    $.fn.plupload = function (arg) {
        var options = arg || defaults,
            cb = $.isFunction(arg) ? arg : null;

        if (!cb) {
            options = $.extend({}, defaults, options);
        };

        var browse_button = options.browse_button || null,
            container     = options.container || null;

        return $(this).each(function () {
            var $el = $(this),
            pluploader = $el.data('Pluploader');

            if (!pluploader) {
                options.browse_button = browse_button || this;
                options.container = container;

                if (!options.container) {
                    var wrapper = $(document.createElement('span'))
                        .addClass('plupload-wrapper file-action');

                    $el.replaceWith(wrapper);
                    wrapper.wrapInner($el);

                    options.container = wrapper.get(0);
                }


                pluploader = new plupload.Uploader(options);
                $el.data('Pluploader', pluploader);
                pluploader.init();
            };

            if ($.isFunction(arg)) {
                cb.call($el, pluploader);
            }
        });
    }
}(jQuery, plupload));