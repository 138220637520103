(function($, w) {
    var $w = $(w), $b;

    $(function () {
        $b = $('body');
    });


    function Modal($el, options) {
        this.$el = $el;
        this.$bg = $el.find('.bg');
        this.$inner = $el.find('.inner');
        this._options = options;

        if (this._options.autoshow) {
            this.show();
        }

        $el.on('click', $.proxy(function (e) {
            if (e.delegateTarget === e.target) {
                e.preventDefault();
                this.hide();
            }
        }, this));
        $el.find('.bg, .close').on('click', $.proxy(function (e) {
            e.preventDefault();
            this.hide();
        }, this));
    }

    Modal.prototype = {
        show: function () {
            this.$el.trigger('modal.show');
            this.$el.show();
            this.$bg.hide().fadeIn(this._options.speed);
            this.$inner.css({top: '-100%', opacity:0}).animate({top: '0%', opacity:1});

            $b.addClass('modal-active');
        },
        hide: function () {
            this.$el.trigger('modal.hide');
            this.$el.fadeOut(this._options.speed);
            $b.removeClass('modal-active');
        }
    }

    var defaults = {
        speed:          300,
        autoshow:       false
    };

    $.fn.Modal = function (arg) {
        var options = arg || defaults,
            cb = $.isFunction(arg) ? arg : null;

        if (!cb) {
            options = $.extend(defaults, options);
        };

        return $(this).each(function () {
            var $el = $(this),
                modal = $el.data('Modal');

            if (!modal) { $el.data('Modal', modal = new Modal($el, $.extend(options, $el.data()))) };

            if ($.isFunction(arg)) {
                cb.call($el, modal);
            }
        });
    };

}(jQuery, window));