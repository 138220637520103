(function ($) {

    $(function () {

        $.ifExists('.modal', function (modal) {
            var form = modal.parents('form');

            modal.Modal();

            $('#display-email').on('click', function (e) {
                e.preventDefault();
                modal.Modal(function (m) {
                    m.show();
                });
            });

            modal
                .find('.continue').on('click', function (e) {
                    e.preventDefault();
                    modal.Modal(function (m) {
                        m.hide();
                        setTimeout(function () {
                            form.find('.submit').removeAttr('disabled');
                        }, 400);
                    });
                });


            $.ifExists(".preview", function (preview) {
                var previewTemplate = preview.html();

                $("[name^='mortgage_advisor']").keyup(update_advisor);

                function update_advisor() {
                    var company     = $("[name='mortgage_advisor[company]']").val();
                    var street      = $("[name='mortgage_advisor[street]']").val();
                    var number      = $("[name='mortgage_advisor[housenumber]']").val();
                    var zipcode     = $("[name='mortgage_advisor[zipcode]']").val();
                    var city        = $("[name='mortgage_advisor[city]']").val();

                    $(".preview").html(previewTemplate.replace("[[advisor]]",
                        company + ", " + street + " " + number + ", " + zipcode + ", " + city));
                }

                update_advisor();
            });

        })

        $('#info-contacted-toggle').on('click', function (e) {
            e.preventDefault();
            var $el = $('#info-contacted');
            if ($el.is(':hidden')) {
                $el.hide().removeClass('hidden').animate({opacity:'show', height:'show'});
            }
        });

        $.ifExists('.mortgage_advisor', function (inputs) {
            var link = $('#display-email').removeClass('hidden').hide();

            function isEmpty(i) {
                return $(this).val() === '';
            };

            if (inputs.filter(isEmpty).length === 0) {
                link.show();
            }

            inputs.on('change blur keyup', function () {
                if (inputs.filter(isEmpty).length === 0) {
                    link.animate({opacity:'show', height:'show'});
                } else {
                    link.animate({opacity:'hide', height:'hide'});
                }
            })

        })
    });

}(jQuery));