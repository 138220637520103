(function ($) {
    var tmpl = $($.parseHTML('<span class="file-type document">Bezig met uploaden\
            (<span class="current"></span>/<span class="maximum"></span>)</span>\
            <div class="progressbar inactive"><div class="fill"></div></div>'));

    function Progressbar() {
        this.$el = tmpl.clone();
        this.$fill = this.$el.find('.fill');
        this.$current = this.$el.find('.current');
        this.$max = this.$el.find('.maximum');
    }

    Progressbar.prototype = {
        getElement: function (){
            return this.$el;
        },
        setMaximum: function (count) {
            this.$max.html(count);
        },
        setCurrent: function (count) {
            this.$current.html(count);
        },
        start: function () {
            this.$el.removeClass('inactive').show();
            this.$fill.removeAttr('style').width(0);
        },
        reset: function () {
            this.$fill.stop(true,true).width(0);
        },
        progress: function (percentage) {
            this.$fill.animate({width: Math.round(percentage) + '%'});
        },
        complete: function () {
            return $.Deferred($.proxy(function (df) {
                this.$fill.animate({width: '100%'}, function () {
                    df.resolve();
                });
            }, this))
        },
        error: function () {
            this.$fill.stop(true,false).css('background', '#ff4500');
        }
    }

    $.makeProgressbar = function () {
        return new Progressbar();
    }
}(jQuery));