(function ($) {
    var uploadAction = $.parseHTML('<span class="file-action file-type document file-upload">Uploaden</span>');
    var mailAction = $.parseHTML('<span class="file-action file-type envelope file-mail">Per post</span>');
    var cancelAction = $.parseHTML('<span class="file-action file-cancel"></span>');
    var errMsg = 'Er is een probleem opgetreden bij het verwerken van uw actie. Probeer het later nogmaals.';

    function RequiredDocument($el, data) {
        this.$el = $el;
        this.$status = $el.find('.status');
        this._locked = false;
        this.data = $.extend({
            files:0,
            post:0
        }, $el.data());

        var actionWrapper = $el.find('.action');

        var action = this.$actions = {
            wrapper : actionWrapper,
            mail: loadElement(actionWrapper.find('.file-mail'), mailAction).on('click', $.proxy(this.mail, this)),
            upload: loadElement(actionWrapper.find('.file-upload')),
            uploadButton: null,
            cancel: loadElement(actionWrapper.find('.file-cancel'), cancelAction).on('click', $.proxy(this.remove, this))
        };

        var self = this;
        configureUpload(action.upload, function ( upload ) {
            var progressbar = $.makeProgressbar();

            upload
            .bind('PostInit', $.proxy(function (upload) {
                upload.settings.multipart_params = this.data;

                this.$actions.uploadButton = $(upload.settings.browse_button);
                this.$actions.upload = $(upload.settings.container);
            }, self));


            var fileUploadCounter = 1;
            upload.bind('FilesAdded', $.proxy(function(upload, files) {
                var self = this;
                upload.disableBrowse(true);

                $.when(this.hideActions())
                    .then(function () {
                        self.$actions.wrapper.append(progressbar.getElement());
                        upload.start();
                        progressbar.start();
                        progressbar.setMaximum(files.length);
                        progressbar.setCurrent(fileUploadCounter);
                    });

            }, self));

            upload.bind('BeforeUpload', $.proxy(function (upload, file) {
                progressbar.reset();
            }, self));

            upload.bind('UploadProgress', $.proxy(function (upload, file) {
                progressbar.progress(file.percent);
            }, self))

            upload.bind('FileUploaded', $.proxy(function (upload, file) {
                progressbar.setCurrent(fileUploadCounter++);
            }, self))

            upload.bind('UploadComplete', $.proxy(function (upload, files) {
                $.when(progressbar.complete())
                    .then(progressbar.getElement().fadeOut())
                    .done(function () {
                    var fileCount = self.data.files + (fileUploadCounter - 1);

                    self.data.files = fileCount;
                    fileUploadCounter = 1;

                    self.showActions();
                    self.$actions.uploadButton.html('Aangeleverd (' + fileCount + '/' + fileCount + ')');
                    self.updateStatus('pending', 'In afwachting van controle');
                    upload.disableBrowse(false);
                });
            }, self));

            upload.bind('Error', $.proxy(function (upload, error) {
                var message = error.message || errMsg;
                this.updateStatus('failed', message);
                this.showActions();
            }, self));
        });
    }

    RequiredDocument.prototype = {
        mail: function (e) {
            if (this._mail_lock || this._locked) {
                return false;
            }
            this._mail_lock = true;

            this.hideActions();
            this.removeStatus();

            $.post('/documenten/will-post', this.data)
                .done($.proxy(function (json) {
                    this.data.post = 1;
                    this.updateStatus(json.status, json.message);
                }, this))
                .fail($.proxy(function () {
                    this.updateStatus('failed', errMsg);
                },this))
                .always($.proxy(function () {
                    this._mail_lock = false;
                    this.showActions();
                }, this));
        },
        remove: function () {
            if (this._remove_lock || this._locked) {
                return false;
            }
            this._remove_lock = true;

            this.hideActions();
            this.removeStatus();

            var self = this;
            $.post('/documenten/remove', this.data)
                .done(function (json) {
                    self.data.post = 0;
                    self.data.files = 0;
                    self.$actions.uploadButton.html('Uploaden');
                    self.updateStatus(json.status, json.message);
                })
                .fail(function () {
                    self.updateStatus('failed', errMsg);
                })
                .always(function () {
                    $.when(self.showActions()).then(function () {
                        self._remove_lock = false;
                    });
                });
        },
        updateStatus: function (state, message) {
            var status = this.$status,
                div = $(document.createElement('div'));

            var df;
            if (!status.is(':empty')) {
                df = this.removeStatus();
            }

            $.when(df).then(function () {
                div.html(message).hide();
                status.append(div).addClass('status-' + state);

                div.fadeIn(400, function () {
                    status.addClass('status-' + state).html(message);
                });
            });
        },
        removeStatus: function () {
            return $.Deferred($.proxy(function (df) {
                var status = this.$status,
                    div = $(document.createElement('div'));

                status.wrapInner(div).find('> div').fadeOut(400, function () {
                    div.remove();
                    status.removeAttr('class').addClass('status').empty();
                    df.resolve();
                });
            }, this))
        },

        showActions: function () {
            this._locked = true;
            return $.Deferred($.proxy(function (df) {
                var wrapper = this.$actions.wrapper,
                    div = $(document.createElement('div')).hide(),
                    actions = [];


                actions.push(this.$actions.upload);

                if (this.data.files) {
                    actions.push(this.$actions.cancel)
                }

                if (!this.data.post && !this.data.files) {
                    actions.push(this.$actions.mail);
                }

                div.append(actions);

                wrapper.append(div);
                div.fadeIn(400, $.proxy(function () {
                    wrapper.append(actions);
                    div.remove();
                    df.resolve();
                    this._locked = false;
                }, this));
            }, this))
        },

        hideActions: function () {
            this._locked = true;
            return $.Deferred($.proxy(function (df) {
                var wrapper = this.$actions.wrapper,
                div = $(document.createElement('div'));

                wrapper.wrapInner(div).find('> div')
                    .fadeOut(400, $.proxy(function () {
                        div.remove();
                        df.resolve();
                        this._locked = false;
                    }, this));
            }, this))
        },
    }

    function loadElement($el, defaultElement) {
        if ($el.length > 0) {
            return $el;
        } else {
            return $(defaultElement).clone();
        }
    }

    // Register additional mime-types
    $(function () {
        mOxie.Mime.addMimeType("application/x-zip-compressed,zip");
    });

    function configureUpload($el, callback) {
        $el.plupload({
            runtimes: 'html5,flash,silverlight,html4',
            url: '/documenten/upload',
            //chunk_size: '200kb',
            rename: false,
            dragdrop: false,
            file_data_name: 'document',
            headers: {
                'X-CSRF-TOKEN': $('#csrf-token').attr('content')
            },
            filters : {
                max_file_size : '15mb',
                mime_types: [
                    {title: "Afbeeldingen", extensions: "gif,jpg,jpeg,bmp,png"},
                    {title: "Archieven", extensions: "zip"},
                    {title: "Documenten", extensions: "txt,doc,docx,pdf"}
                ]
            }
        }).plupload(callback);
    }

    var defaults = {

    };

    $.fn.RequiredDocument = function (arg) {
        var options = arg || defaults,
            cb = $.isFunction(arg) ? arg : null;

        if (!cb) {
            options = $.extend(defaults, options);
        };

        return $(this).each(function () {
            var $el = $(this),
                requiredDocument = $el.data('RequiredDocument');

            if (!requiredDocument) { $el.data('RequiredDocument', requiredDocument = new RequiredDocument($el, options)) };

            if ($.isFunction(arg)) {
                cb.call($el, requiredDocument);
            }
        });
    };
}(jQuery));