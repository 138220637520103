(function ($, w) {
    var $w = $(w);

    $(function(){
        // *************************************************************************
        // Selectric
        // *************************************************************************
        $('select').selectric();

        $('.tooltip').Tooltip({click:false});

        /**
         * Navigation
         */
        (function(menu, nav) {
            $w.on('resize', function() {
                if (menu.is(':visible')) {
                    nav.hide();
                } else {
                    nav.show();
                }
            }).trigger('resize');

            menu.on('click', function() {
                if (nav.is(':visible')) {
                    nav.slideUp();
                } else {
                    nav.slideDown();
                }
            });

        }($('.menu'), $('.nav')));

        $('.digits-only').on('change blur', function () {
            var $this = $(this);
            $this.val($this.val().replace(/[^0-9]/g,''));
        })

        $('.postalcode').on('change blur', function () {
            var $this = $(this);
            $this.val($this.val().replace(/\s/g, ''));
        }).trigger('change');
    });

}(jQuery, window));