/*
 * @url: http://html5boilerplate.com/
 */
(function($) {
    // Avoid `console` errors in browsers that lack a console.
    'use strict';
    var method,
        methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ],
        length = methods.length,
        console = (window.console = window.console || {});

    while ( length-- ) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = $.noop;
        }
    }
}(jQuery));

/*
 * $.ifExists()
 */
(function ($) {
    'use strict';
    /*
     * jQuery convenience methods
     */
    $.ifExists = function ($e, cb) {
        if ($e instanceof jQuery === false) {
            $e = $($e);
        }
        if ($e.length > 0) {
            cb.call($e, $e);
        }
        return $e;
    };
}(jQuery));