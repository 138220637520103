(function ($) {

    function hideRows(elements, val, animate) {
        animate = animate || false;

        if (animate) {
            elements
                // Find first X elements and display them ( animated )
                .filter(':lt(' + val +'):hidden')
                    .removeClass('hidden')
                    .hide()
                    .animate({opacity:'show', height:'show'})
                .end()
                // Find all other elements and hide them ( animated )
                .filter(':gt(' + (val-1) +'):visible')
                    .animate({opacity:'hide', height:'hide'})
        } else {
            elements
                // Find first X elements and display them
                .filter(':lt(' + val +')')
                    .removeClass('hidden')
                    .show()
                .end()
                // Find all other elements and hide them
                .filter(':gt(' + (val-1) +')')
                    .hide()
        }
    }

    $(function () {

        $.ifExists('.modal', function (modal) {
            modal.Modal();

            $('#estimate_damage').on('click', function (e) {
                e.preventDefault();
                modal.Modal(function (m) {
                    m.show();
                });
            });

            modal.find('.continue').on('click', function (e) {
                e.preventDefault();
                modal.Modal(function (m) {
                    $(".alert-calculator").hide();
                    m.hide();
                });
            });

            /**
             * Loan parts
             */
            (function (wrapper, button, template) {
                var domTemplate = $($.parseHTML(template.html()));

                function reindex() {
                    var counter = 1;
                    $(".part", wrapper).each(function() {
                        $("a.tooltip", $(this).parent()).hide();
                        if (counter === 1) {
                            $("a.tooltip", $(this).parent()).show().Tooltip({click:false});;
                        }
                        $(this).text(counter++);
                    });
                }

                button.on('click', function (e) {
                    var tmplCopy = domTemplate.clone(true).hide();
                    e.preventDefault();
                    wrapper.append(tmplCopy);
                    tmplCopy.animate({opacity:'show', height:'show'});
                    reindex();
                });

                wrapper.on('click', '.remove', function (e) {
                    var $el = $(this).parents('.field-row').eq(0);
                    e.preventDefault();
                    $el.animate({opacity:'hide', height:'hide'}, 400, function () {
                        $el.remove();
                        reindex();
                    });
                });

                wrapper.on('keyup change blur', '[name="loan-parts[][percentage]"]', function() {
                    $(this).val($(this).val().replace(',', '.'));
                });

                wrapper.on('keyup change blur', '[name="loan-parts[][amount]"]', function() {
                    $(this).val($(this).val().replace(/[^0-9]/, ''));
                });

                wrapper
                    .parents('form')
                    .on('submit', function () {
                        // Find all rows
                        wrapper.find('.field-row').each(function (i) {
                            // Find all inputs
                            $(this).find('input').each(function () {
                                // Replace input[name]
                                var input = $(this);
                                input.attr('name', input.attr('name').replace('[]','['+i+']'));
                            })
                        });
                    });
            }(
                $('#loan-parts'),
                $('#add-loan-part'),
                $('#loan-part-template'))
            );


            (function ( nop, wrapper) {
                nop.on('change', function (e) {
                    hideRows(wrapper.find('.field-row'), nop.val(), false);
                });
                hideRows(wrapper.find('.field-row'), nop.val(), false);

                wrapper.on('keyup change blur', '[name$="[income]"]', function() {
                    $(this).val($(this).val().replace(/[^0-9]/, ''));
                });
            }(
                $('#number_of_persons'),
                $('#incomes')
            ));
        });

        $.ifExists('.person', function (persons) {
            /**
             *  Show / Hide address row
             */
            persons.on('change', '.sameAddress', function (e) {
                var addressRow = $(e.delegateTarget).find('.address-row');

                if ( $(this).is(':checked') ) {
                    addressRow.animate({opacity:'hide', height:'hide'});
                } else {
                    addressRow.animate({opacity:'show', height:'show'});
                }
            })
                // Remove address row hidden class
                .find('.address-row.hidden')
                    .hide().removeClass('hidden');

            /**
             *  Show / Hide additional persons
             */
            (function ( nop ) {
                nop.on('change', function (e) {
                    hideRows(persons, nop.val(), true);
                });
                hideRows(persons, nop.val(), false);
            }($('#number_of_persons')));

        });

        $.ifExists('#legal_insurance', function ($el) {
            var radio = $('#legal_insurance_toggle').find('input[type=radio]'),
                notice = $el.find('.notice').hide().removeClass('hidden');

            radio.on('change', function () {
                if (radio.filter(':checked').val() === 'yes') {
                    $el.animate({opacity:'show', height:'show'}, 200);
                } else {
                    $el.animate({opacity:'hide', height:'hide'}, 200);
                }
            });

            $el.find('.legal_insurance_company').on('change', function () {
                var $selected = $(this).find(':selected');
                if ($selected.val() === '' || $selected.data('hide-notice') === true) {
                    notice.animate({opacity:'hide', height:'hide'}, 200);
                } else {
                    notice.animate({opacity:'show', height:'show'}, 200);
                }
            }).trigger('change');

            if ($el.hasClass('hidden')) {
                $el.hide().removeClass('hidden');
            }
        });
    });

}(jQuery));
